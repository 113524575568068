import * as React from 'react';
import { rhythm } from '../utils/typography';
import { Link } from 'gatsby';
import slugify from '../utils/slugify';

type Node = {
    html: string
    excerpt: string
    frontmatter: {
        title?: string
        date: string
        description: string
        tags:string[]
        publish?:boolean
    }
    fields: {
        slug: string
        html: string
    }
}

interface PostProps {
    node: Node;
    excerptOnly?: boolean;
    type?: 'embedded' | 'standalone';
}


export const Post = ({ node, excerptOnly, type }: PostProps) => {
    if (node.frontmatter.publish != null && node.frontmatter.publish == false)
        return null;
    const title = node.frontmatter.title || node.fields.slug;
    type = type != null ? type : 'embedded';
    const Title = ()=> <h1 style={{
        marginTop: rhythm(1/4),
        marginBottom: rhythm(1 / 4)}}>
        <Link style={{ boxShadow: `none`, color: `inherit` }} to={node.fields.slug}>
            {node.frontmatter.title}
        </Link>
    </h1>;

    const Tagged = ()=> {
        if (node.frontmatter.tags.length == 0)
            return <React.Fragment/>;
        return <div style={{marginBottom:rhythm(1)}}>
        <b>Tagged: </b>
        {node.frontmatter.tags.map((tag, index)=><Link key={index} style={{marginRight:rhythm(0.25), boxShadow: `none`}} to={`/tag/${slugify(tag)}`}>{`${tag} `}</Link>)}
    </div>}

    return (
        <article key={node.fields.slug} style={{marginBottom:rhythm(2)}}>
            <header>
                <Title/>
                <small>{node.frontmatter.date} - Søren Alsbjerg Hørup</small>
            </header>
            <section>
                <div
                    dangerouslySetInnerHTML={{
                        __html: excerptOnly ? node.excerpt : node.html,
                    }}
                />
            </section>
            <Tagged/>
        </article>
    )
}